import React, {useState} from 'react';
import GumroadFollowForm from './GumroadFollowForm';
import styled from 'styled-components';
import Menu from './Menu';
import Footer from './Footer';
import Navbar from './Navbar';
import img1 from '../images/group1-min.png'
import img2 from '../images/group2-min.png'
import img3 from '../images/group3-min.png'

import DesktopMenu from './DesktopMenu';
import gif from '../images/spin.gif'



const Workplace = () => {




    return (
        <>
<Navbar/>
<DeskWrap>
<DesktopMenu/>
<Gif src= {gif}/>

<Wrapper>


<Body>

    
<h1>Spectral Center Group Hypnosis Sessions for Workplaces</h1>
      <Body>
        <Strong>Unlock Your Team’s Potential with Group Hypnosis</Strong>
        <p>
          Boost team performance, reduce stress, and unlock creative problem-solving—all in just 90 minutes. Spectral Center’s
          Group Hypnosis Sessions provide a scientifically-backed method to enhance focus, morale, and motivation, helping your team thrive.
        </p>
        <p>
          Unlike traditional corporate wellness activities, hypnosis directly engages the subconscious mind—where true behavioral change begins.
          By combining deep relaxation with guided visualization, these sessions help employees break through mental roadblocks, improve focus and creativity,
          and align with company goals.
        </p>
      </Body>

      <Strong>Why Group Hypnosis?</Strong>
      <Para>
        <Text>
          <Strong>Boost Efficiency and Focus</Strong>
          <p>Group hypnosis promotes clarity of mind by reducing mental clutter and stress, enabling employees to work with greater focus and intention.</p>
          <p>Visualization techniques enhance problem-solving and encourage innovative thinking, allowing your team to meet challenges head-on.</p>
        </Text>
        <Text>
          <Strong>Strengthen Team Morale and Cohesion</Strong>
          <p>Shared hypnotic experiences create a sense of connection and trust, fostering deeper collaboration and understanding among team members.</p>
          <p>Hypnosis helps individuals release limiting beliefs, making room for a more positive and supportive team environment.</p>
        </Text>
      </Para>
      
      <Para>
        <Text>
          <Strong>Enhance Motivation and Alignment</Strong>
          <p>Guided visualization techniques help teams connect emotionally to the company’s vision and mission, reigniting their purpose and drive.</p>
          <p>Employees leave sessions feeling energized, motivated, and ready to tackle organizational goals.</p>
        </Text>
        <Text>
          <Strong>Support Employee Wellness</Strong>
          <p>Hypnosis has been scientifically shown to reduce stress, improve emotional regulation, and create a sense of calm. A relaxed, resilient team is a productive team.</p>
        </Text>
      </Para>
      
      <Strong>What to Expect</Strong>
      <p>In just one session, participants often report increased focus, reduced stress, and a renewed sense of purpose.</p>
      <p>Each 90-minute session includes:</p>
      <ul>
        <li><Strong>Customized Content:</Strong> Tailored hypnosis to reflect your company’s mission and objectives.</li>
        <li><Strong>Relaxation and Visualization:</Strong> Guided deep relaxation and focus on growth.</li>
        <li><Strong>Actionable Integration:</Strong> Practical tools employees can use daily.</li>
      </ul>
      
      <Strong>Session Themes</Strong>
      <ul>
        <li>• Stress Relief and Resilience</li>
        <li>• Creativity and Innovation</li>
        <li>• Team Cohesion</li>
        <li>• Vision and Mission Alignment</li>
      </ul>
      
      <Strong>Benefits of Hypnosis for Your Workplace</Strong>
      <ul>
        <li>• Increased focus, clarity, and efficiency.</li>
        <li>• Reduced stress and improved emotional well-being.</li>
        <li>• Stronger interpersonal relationships and teamwork.</li>
        <li>• Enhanced creativity and innovative thinking.</li>
        <li>• Renewed motivation and alignment.</li>
      </ul>
      
      <Strong>Why Spectral Center?</Strong>
      <p>
        <b>Julia E. Dyck</b>, founder of Spectral Center Hypnosis, brings a unique intersection of hypnotherapy, business administration, and creative problem-solving
        to the corporate wellness space. With expertise in subconscious transformation and visualization techniques, she helps teams unlock their full potential
        and achieve workplace alignment.
      </p>
      
      <Strong>Ready to Energize Your Team?</Strong>
      <p>
        Let’s create a transformational experience for your workplace. Whether you’re an NGO, a not-for-profit, or a corporate organization,
        we’ll design a session that reflects your values and drives results.
      </p>
      <p>
        <b>Book a free consultation</b> to explore how hypnosis can transform your team’s performance.
      </p>
      <p>
        📩 <WebLink href="mailto:spectralcenterhypnosis@gmail.com">spectralcenterhypnosis@gmail.com</WebLink> <br/>
        📞 Phone: +32 467 882281 <br/>
        🌐 <WebLink href="https://spectral.center" target="_blank">https://spectral.center</WebLink>
      </p>


</Body>
      
    </Wrapper>
    </DeskWrap>
    <Footer/>
    </>
    );
    
    }


    const Images = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5%;

    @media (min-width: 500px) {
    max-width: 100%;
flex-direction: row;
justify-content: flex-start;
margin-bottom: 1%;


   }

@media (min-width: 1025px) { 
margin-top: 1%;

}
    
    `
    const Strong = styled.h3`
    font-weight: 600;
    `

    const Gif = styled.img`
    max-width: 100px;
    position: absolute;
    margin-left: 200px;
    z-index: 1000;
    top: 5px;
    right: 5px;

    @media (min-width: 1025px) {
        max-width: 150px;
        position: absolute;
        z-index: 1000;
        top: 600px;
        right: 100px;
    }
    `
const DeskWrap = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    @media (min-width: 1000px) {
flex-direction: row;

}
`


const Wrapper=styled.div`
    display: flex;
    padding: 2%;
    flex-direction: column;
    justify-content: space-between;
    height: 90%;
    width: 90%;
    margin: 2%;

    @media (min-width: 500px) { }

    @media (min-width: 1025px) {

        justify-content: flex-start;
        height: 100%;
        padding: 0%;
        margin-right: 10%;
        width: 100%;


     }

    `

const WebLink = styled.a`
color: black;
text-decoration: underline;`

const Body = styled.div`
   font-family: var(--font-body);
   margin-top: 3%;

   
   @media (min-width: 1035px) {

margin-top: 0%;


}
`


const H1 = styled.h1`
font-size: 20px;
cursor: pointer;

&:hover {
    color: #ffc9eb;
  }
`
   const Img = styled.img`
max-width: 100%;
    border-radius: 1rem;
    margin-top: 2%;


   @media (min-width: 500px) {
    max-width: 200px;
    margin-left: 1%;
    margin-right: 1%; 
   border-radius: 5%;
   max-height: fit-content;

   }

@media (min-width: 1025px) { 
max-width: 300px;
margin-top: 0;
margin-left: 2%;
margin-right: 2%;

}



   `
const Para = styled.div`

@media (min-width: 500px) { 
max-width: 100%;
display:flex;
flex-direction: row;
margin-top: 5%;

}


@media (min-width: 1025px) { 
max-width: 90%;
display:flex;
flex-direction: row;
margin-top: 5%;

}
`
const Text = styled.div`
@media (min-width: 1025px) { 


width: 350px;
    text-align: left;
    margin-right: 100px;
}

`

export default Workplace;