

import { useState, useEffect } from "react";
import styled from "styled-components";

const images = [
 


'../reviews/photo2.jpg',
'../reviews/photo3.jpg',

'../reviews/photo5.jpg',
'../reviews/photo7.jpg',
'../reviews/photo8.jpg',



  

];

const Slideshow = () => {
const [startIndex, setStartIndex] = useState(0);
const numVisible = 3; // Show 3 images at a time

useEffect(() => {
  const interval = setInterval(() => {
    setStartIndex((prevIndex) => (prevIndex + 1) % images.length);
  }, 3000); // Change every 3 seconds

  return () => clearInterval(interval);
}, []);

return (
  <SlideshowContainer>
    <ImageRow>
      {images
        .slice(startIndex, startIndex + numVisible)
        .concat(images.slice(0, Math.max(0, startIndex + numVisible - images.length)))
        .map((img, index) => (
          <SlideImage key={index} src={img} alt={`Slide ${index}`} />
        ))}
    </ImageRow>
  </SlideshowContainer>
);
}


const SlideshowContainer = styled.div`
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  overflow: hidden;
`;

const ImageRow = styled.div`
  display: flex;
  transition: transform 0.7s ease-in-out;
`;

const SlideImage = styled.img`
max-width:300px;
  width: 33.33%;
  height: 300px;
  object-fit: cover;
  margin: 0 5px;
  border-radius: 1em;
`;




export default Slideshow;