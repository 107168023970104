import React, {useState} from 'react';
import GumroadFollowForm from './GumroadFollowForm';
import styled from 'styled-components';
import Menu from './Menu';
import Footer from './Footer';
import Navbar from './Navbar';
import img1 from '../images/group1-min.png'
import img2 from '../images/group2-min.png'
import img3 from '../images/group3-min.png'

import DesktopMenu from './DesktopMenu';
import gif from '../images/spin.gif'
import { Link } from 'react-router-dom';



const Group = () => {




    return (
        <>
<Navbar/>
<DeskWrap>
<DesktopMenu/>
<Gif src= {gif}/>

<Wrapper>

    <h1>Group Hypnosis</h1>

<Body>

<Para>
    <Text>

    <p>Group hypnosis sessions invite participants to harness the power of the trance state and the potential of the subconscious mind to explore realms of pleasure and fulfillment.</p>
    
    <p>These sessions combine the practice of hypnotherapy with the synergy of voice, electronics, and resonant instruments. In this deeply relaxed state, a hypnotic narrative and live soundscape guide listeners on an odyssey through the theater of the mind, opening portals of possibility.
    </p>
    
    <p>Unlike 1:1 therapy, group hypnosis focuses on collective healing from the stresses and challenges of modern life, benefiting from the power of communal rest.
    </p>
    </Text>
    <Img src= {img1}/>
    </Para>


    
    <OfferingsContainer>
        <h1>Offerings include:</h1>
        <OfferingsList>
          <OfferingsItem>
            <Strong>Group Sessions:</Strong> Open to the public, in person and online, fostering a sense of shared experience.
          </OfferingsItem>
          <WebLink href="https://www.tr4nc3.com/" target="blank">    <OfferingsItem>
            <Strong> t.r.a.n.c.e community hypnosis </Strong> Monthly community sessions for small groups in Brussels, with sound by Diana Duta.
          </OfferingsItem>
          </WebLink>

            <Link to="/workplace">
          <OfferingsItem>
              <Strong>Team / Workplace Sessions</Strong>
           Designed to reduce stress, boost productivity, and enhance overall well-being in professional environments.
          </OfferingsItem>
            </Link>
        </OfferingsList>
      </OfferingsContainer>

<Para>



<Img src= {img3}/>
</Para>



</Body>
      
    </Wrapper>
    </DeskWrap>
    <Footer/>
    </>
    );
    
    }




    const Images = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5%;

    @media (min-width: 500px) {
    max-width: 100%;
flex-direction: row;
justify-content: flex-start;
margin-bottom: 1%;


   }

@media (min-width: 1025px) { 
margin-top: 1%;

}
    
    `
    const Strong = styled.h3`
    font-weight: 600;
    color: black;
    text-decoration: none;
    `

    const Gif = styled.img`
    max-width: 100px;
    position: absolute;
    margin-left: 200px;
    z-index: 1000;
    top: 5px;
    right: 5px;

    @media (min-width: 1025px) {
        max-width: 150px;
        position: absolute;
        z-index: 1000;
        top: 600px;
        right: 100px;
    }
    `
const DeskWrap = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    @media (min-width: 1000px) {
flex-direction: row;

}
`


const Wrapper=styled.div`
    display: flex;
    padding: 2%;
    flex-direction: column;
    justify-content: space-between;
    height: 90%;
    width: 90%;
    margin: 2%;

    @media (min-width: 500px) { }

    @media (min-width: 1025px) {

        justify-content: flex-start;
        height: 100%;
        padding: 0%;
        margin-right: 10%;
        width: 100%;


     }

    `

const WebLink = styled.a`
color: black;
text-decoration: none;`

const Body = styled.div`
   font-family: var(--font-body);
   margin-top: 3%;

   
   @media (min-width: 1035px) {

margin-top: 0%;


}
`


const H1 = styled.h1`
font-size: 20px;
cursor: pointer;

&:hover {
    color: #ffc9eb;
  }
`
   const Img = styled.img`
max-width: 100%;
    border-radius: 1rem;
    margin-top: 2%;


   @media (min-width: 500px) {
    max-width: 200px;
    margin-left: 1%;
    margin-right: 1%; 
   border-radius: 5%;
   max-height: fit-content;

   }

@media (min-width: 1025px) { 
max-width: 300px;
margin-top: 0;
margin-left: 2%;
margin-right: 2%;

}



   `
const Para = styled.div`

@media (min-width: 500px) { 
max-width: 100%;
display:flex;
flex-direction: row;
margin-top: 5%;

}


@media (min-width: 1025px) { 
max-width: 90%;
display:flex;
flex-direction: row;
margin-top: 5%;

}
`
const Text = styled.div`
text-align: justify;
`



const OfferingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin: 2rem 0;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const OfferingsList = styled.ol`
  list-style-type: none;
  padding: 0;
  margin: 2%;
  display: flex;
  flex-direction: row;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const OfferingsItem = styled.li`
    gap: 0.5rem;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 2rem;
    border: 2px solid #000;
    border-radius: 10px;
    margin: 2rem 0;
    width: 200px;
    height: 150px;
    color:black;

    &:hover {
    background-color:  #ffc9eb;
  }

  @media (max-width: 768px) {
   width:80%;
  }
`;




export default Group;