import React from 'react';
import Reset from './products/Reset';
import styled from 'styled-components';
import Membership from './products/Membership';
import Menu from './Menu';
import Footer from './Footer';
import MindMakeover from './products/MindMakeover';
import Confidence from './products/Confidence';
import Morning from './products/Morning';
import Optimism from './products/Optimism';
import Navbar from './Navbar';
import DesktopMenu from './DesktopMenu';
import GumroadProducts from './GumroadProducts';
import GumroadFollowForm from './GumroadFollowForm';

const Shop= () => {

    return (
        <>

      
<Navbar/>
<DeskWrap>

<DesktopMenu/>
<div>
<H1>Sign Up for the newsletter for a free self-hypnosis recording for finding purpose and presence </H1>
<Form>
     <GumroadFollowForm/>
     </Form>



<GumroadProducts/>
</div>

</DeskWrap>

<Footer/>

</>
    )
}


const H1 = styled.h1`
text-align: center;`


const DeskWrap = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    @media (min-width: 1000px) {
flex-direction: row;

}
`
const Grid = styled.div`

display:flex;
flex-direction: column;


    
@media (min-width: 500px) {
    display: flex;
    flex-direction: row;
    width:100%;
    flex-wrap: wrap;
}

@media (min-width: 1000px) {

}  `

const Item = styled.div`
width: 100%;

@media (min-width: 500px) {
   width: 50%;
}

@media (min-width: 1000px) {
   width: 30%;
}
`

const Form = styled.div`

width: 100%;
display: flex;
align-items: center;
justify-content: center;
margin-top: 2%;
margin-bottom: 2%;
padding-top: 2%;


@media (min-width: 500px) {

}

@media (min-width: 1000px) {
margin-top: 2%;
margin-bottom: 5%;
padding: 0%;


}  `



export default Shop;